<template>
  <NuxtLayout>
    <main
      role="main"
      class="container my-20 flex max-w-container flex-col items-center gap-y-5"
    >
      <h1>
        {{ error.statusCode }} - {{ error.message }}
      </h1>

      <UiButton
        class="block"
        @click="handleError"
      >
        Terug naar home
      </UiButton>
    </main>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
