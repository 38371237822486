import revive_payload_client_8smfXlz8T2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gZWHDvrbfG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZZrm0VGi7j from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VazWVox23X from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_QShQLauX7U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_a7z0PD1bKq from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_47Nm57jvGX from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KnfPPBL1iU from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_EyBqDRQPRe from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_aauprd98hL from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.2_react-dom@18.3.1_react@18.3.1_rollup@4.26.0_typescript@5.6.3_vue@3.5.12/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_hiohBqHg3Y from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.26.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_EjR3ipLBGx from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ObTBmNDEqR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import plugin_is026OCl7o from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.4_rollup@4.26.0_vue@3.5.12/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_dqxpFxqZQI from "/vercel/path0/node_modules/.pnpm/nuxt-cookie-first@0.1.9_rollup@4.26.0_vite@5.4.11/node_modules/nuxt-cookie-first/dist/runtime/plugin.client.js";
import plugin_jB4cUb6WyF from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_next-auth@4.21.1_rollup@4.26.0_typescript@5.6.3/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_hlHskyOqzS from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.7.5_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import apollo_xGKh8rHczw from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_esbuild@0.23.1_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_ro_rqxwyagv3uviemubekkcz2kwfm/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_NcSCflElDf from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_esbuild@0.23.1_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_ro_rqxwyagv3uviemubekkcz2kwfm/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import errors_GSFBaeIW0a from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_esbuild@0.23.1_nuxt@3.12.4_rollup@4.26.0_tailwindcss@3.4.14__wwn5kfkcaweo3emhsff5h2uvdq/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_7Lecldzj78 from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_esbuild@0.23.1_nuxt@3.12.4_rollup@4.26.0_tailwindcss@3.4.14__wwn5kfkcaweo3emhsff5h2uvdq/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import AutoScroll_yizJF5s5yd from "/vercel/path0/plugins/AutoScroll.ts";
export default [
  revive_payload_client_8smfXlz8T2,
  unhead_gZWHDvrbfG,
  router_ZZrm0VGi7j,
  _0_siteConfig_VazWVox23X,
  payload_client_QShQLauX7U,
  navigation_repaint_client_a7z0PD1bKq,
  check_outdated_build_client_47Nm57jvGX,
  chunk_reload_client_KnfPPBL1iU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EyBqDRQPRe,
  plugin_aauprd98hL,
  plugin_hiohBqHg3Y,
  switch_locale_path_ssr_EjR3ipLBGx,
  i18n_ObTBmNDEqR,
  formkitPlugin_pZqjah0RUG,
  plugin_is026OCl7o,
  plugin_client_dqxpFxqZQI,
  plugin_jB4cUb6WyF,
  plugin_hlHskyOqzS,
  apollo_xGKh8rHczw,
  sofie_required_version_NcSCflElDf,
  errors_GSFBaeIW0a,
  i18n_7Lecldzj78,
  AutoScroll_yizJF5s5yd
]