import { default as chat1HrNrTjf3wMeta } from "/vercel/path0/pages/chat.vue?macro=true";
import { default as dataveiligheidPgc4cam9wpMeta } from "/vercel/path0/pages/dataveiligheid.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacybeleidsmMbxHC5NWMeta } from "/vercel/path0/pages/privacybeleid.vue?macro=true";
import { default as sessionsjOb19KiNtwMeta } from "/vercel/path0/pages/sessions.vue?macro=true";
import { default as connectyMs97wgrRoMeta } from "/vercel/path0/pages/tool/connect.vue?macro=true";
import { default as indexQKW093YUYiMeta } from "/vercel/path0/pages/tool/index.vue?macro=true";
import { default as select_46clienteDd7whiyvBMeta } from "/vercel/path0/pages/tool/select.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as toollO65HrhybJMeta } from "/vercel/path0/pages/tool.vue?macro=true";
import { default as component_45stubLOE5NYk7zUMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLOE5NYk7zU } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "chat",
    path: "/chat",
    meta: chat1HrNrTjf3wMeta || {},
    component: () => import("/vercel/path0/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: "dataveiligheid",
    path: "/dataveiligheid",
    component: () => import("/vercel/path0/pages/dataveiligheid.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacybeleid",
    path: "/privacybeleid",
    component: () => import("/vercel/path0/pages/privacybeleid.vue").then(m => m.default || m)
  },
  {
    name: "sessions",
    path: "/sessions",
    component: () => import("/vercel/path0/pages/sessions.vue").then(m => m.default || m)
  },
  {
    name: toollO65HrhybJMeta?.name,
    path: "/tool",
    meta: toollO65HrhybJMeta || {},
    component: () => import("/vercel/path0/pages/tool.vue").then(m => m.default || m),
    children: [
  {
    name: "tool-connect",
    path: "connect",
    component: () => import("/vercel/path0/pages/tool/connect.vue").then(m => m.default || m)
  },
  {
    name: "tool",
    path: "",
    component: () => import("/vercel/path0/pages/tool/index.vue").then(m => m.default || m)
  },
  {
    name: "tool-select",
    path: "select",
    component: () => createClientPage(() => import("/vercel/path0/pages/tool/select.client.vue").then(m => m.default || m))
  }
]
  },
  {
    name: component_45stubLOE5NYk7zUMeta?.name,
    path: "/human",
    component: component_45stubLOE5NYk7zU
  }
]