import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_esbuild@0.23.1_nuxt@3.12.4_rollup@4.26.0_tailwindcss@3.4.14__wwn5kfkcaweo3emhsff5h2uvdq/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.1_rollup@4.26.0_stylelint@16.10.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/vercel/path0/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_esbuild@0.23.1_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_ro_rqxwyagv3uviemubekkcz2kwfm/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  tool: () => import("/vercel/path0/middleware/tool.ts"),
  auth: () => import("/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_next-auth@4.21.1_rollup@4.26.0_typescript@5.6.3/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}